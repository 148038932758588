module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eight PR & Marketing","short_name":"EightPR","start_url":"/","background_color":"#ffffff","theme_color":"#00c7ff","display":"minimal-ui","icon":"src/images/BodyImages/Favcon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6c55795e85d7120098ab327df9e0650f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-ZQBC3VQ4GZ","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
